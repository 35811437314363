import React from 'react'
import './RazorpayCheckout.css'
import { Url } from '../../EnvoirnmentSetup/APIs';
import closePopup from "../../assets/closepopup.svg";
import { useSelectedCardContext } from '../../Context/Context';
import { handleDecrypt, handleEncrypt } from '../../constants/constantFunctions';
import { getAPICall, postAPICall, putAPICall } from '../../APIMethods/APIMethods';
import { ComponentAndScreenConstant } from '../../constants/componentAndScreenConstant';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

function RazorpayCheckout({
    isOpen,
    onClose,
    setData
}) {
    const navigate = useNavigate()
    const { selectedCard, setSelectedCard, setIsLoading, setWarning, setAlert } = useSelectedCardContext()
    const [paymentModal, setPaymentModal] = React.useState(false)
    const [CryptoJSKey, setCryptoJSKey] = React.useState('')
    const [userData, setUserData] = React.useState()
    const [priceDetails, setPriceDetails] = React.useState({

    })
    const [promoCodeData, setPromoCodeData] = React.useState()
    const [promoCode, setPromoCode] = React.useState('')

    React.useEffect(() => {
        setPromoCodeData()
        getRazorPayKey()
        getUserDetails()
    }, [])

    const getRazorPayKey = () => {
        setIsLoading(true)
        getAPICall(Url.getKeyAndPricing)
            .then(response => {
                setIsLoading(false)
                if (response.SUCCESS) {
                    setCryptoJSKey(response.TOKEN)
                    response.DATA.map((i) => {
                        if (i?.licenseType == 'STUDENT' && i?.status == true) {
                            let taxPercent = 18
                            let price = i?.price
                            let discountCoupon = 0
                            let gstAmount = (taxPercent * price) / 100
                            setPriceDetails({
                                subscriptionFee: price,
                                gstAmount,
                                discountCoupon,
                                payableAmount: price + gstAmount - discountCoupon,
                                gst: taxPercent,
                            })
                        }
                    })
                } else {
                    // setWarning('Something went wrong please try again after some time')
                }
            })
    }

    const getOdderId = () => {
        try {
            let encryptedAmount = handleEncrypt(priceDetails.payableAmount, CryptoJSKey)
            // console.log('encryptedAmount ....', encryptedAmount);
            const OrderDetails = {
                customerName: userData?.fullName,
                phoneNumber: userData?.mobileNumber,
                amount: encryptedAmount,
                email: userData?.userName
            }
            setIsLoading(true)
            postAPICall(Url.getOrderIdAndUpdatePaymentStatus, OrderDetails)
                .then(response => {
                    setIsLoading(false)
                    if (response.SUCCESS) {
                        const { secretKey, razorpayOrderId } = response.DATA
                        let newsecretKey = handleDecrypt(secretKey, CryptoJSKey)
                        let newrazorpayOrderId = handleDecrypt(razorpayOrderId, CryptoJSKey)
                        // let newsecretKey = handleDecrypt('eX4uwjMXC9NENI1rlRDhHtROqZBy6sFf6Jn2zVmFQCc=', CryptoJSKey)
                        // let newrazorpayOrderId = handleDecrypt('xInK9l3Ofaw/bhzqdnJNw6Ruig5XHhsgSQohok9sXY0=', CryptoJSKey)
                        handlePayment(newsecretKey, newrazorpayOrderId, userData?.fullName, userData?.userName, userData?.mobileNumber)
                    }
                }).catch(err => console.log(err));

        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }

    const handlePayment = (razorPayKeyID, orderId, customerName, customerEmail, customerMobileNumber/* , resolveCallback, rejectCallback */) => {
        const options = {
            key: razorPayKeyID, // Replace with your Razorpay key ID
            name: customerName,
            method: {
                "upi": {
                    "flow": "collect" // Enable UPI apps, disable QR code
                }, "card": true, // Enable card method
                "netbanking": true, // Enable netbanking
                "wallet": true, // Enable wallet method
                "paylater": true, // Enable pay later method
            },
            image: 'https://campusplacement.in/static/media/quikHireLogo.46827af88d6aa7f5fe4d.png',//'https://www.shareresume.co/static/media/QuickHireLogo.b384512b4e42b7e21cba42c44f918001.svg',
            order_id: orderId,
            handler: function (response) {
                console.log({ response })
                if (!!response.razorpay_payment_id) {
                    const { razorpay_order_id, razorpay_payment_id, razorpay_signature } = response
                    confirmPayment(razorpay_order_id, razorpay_payment_id, razorpay_signature)
                    // resolveCallback(razorpay_order_id, razorpay_payment_id, razorpay_signature)                    
                }
            },
            prefill: {
                name: customerName,
                email: customerEmail,
                contact: customerMobileNumber
            },
            theme: {
                color: '#007749'
            }
        };
        const rzp = new window.Razorpay(options);
        rzp.on('payment.failed', function (response) {
            console.log('Payment failed:', response.error);
            const { code, description, source, step, reason, metadata } = response.error
            // getUserDetails()
            failedPayment(metadata.order_id, metadata.payment_id, code, description, source, step, reason)
            // rejectCallback(metadata.order_id, metadata.payment_id, code, description, source, step, reason)
            setWarning(`Payment Failed \nUnfortunately, your payment could not be processed. Please check your payment details and try again.`)
        });

        rzp.open();
    };

    const confirmPayment = (razorpayOrderId, razorpayPaymentId, razorpaySignature) => {
        try {
            let encryptedrazorpayOrderId = handleEncrypt(razorpayOrderId, CryptoJSKey)
            let encryptedrazorpayPaymentId = handleEncrypt(razorpayPaymentId, CryptoJSKey)
            let encryptedrazorpaySignature = handleEncrypt(razorpaySignature, CryptoJSKey)
            const PurchaseDetails = {
                razorpayOrderId: encryptedrazorpayOrderId,
                razorpayPaymentId: encryptedrazorpayPaymentId,
                razorpaySignature: encryptedrazorpaySignature,
            }
            putAPICall(Url?.getOrderIdAndUpdatePaymentStatus, PurchaseDetails)
                .then((res) => console.log("payment confirmation response", res))
                .catch(err => console.log(err))

            let encryptedAmount = handleEncrypt(priceDetails.payableAmount, CryptoJSKey)
            const PurchaseDetailsStudent = {
                "noOfLicense": 1,
                "studentLicenseAudit": {
                    "noOfLicense": 1,
                    "paymentType": "PAID",
                    "studentLicensePaymentDto": {
                        "paymentAmount": encryptedAmount,
                        "razorpayPaymentId": encryptedrazorpayPaymentId,
                        "razorpayOrderId": encryptedrazorpayOrderId,
                        "paymentStatus": "PAID"
                    }
                }
            }
            setIsLoading(true)
            postAPICall(Url.paymentSuccessStatus, PurchaseDetailsStudent)
                .then(response => {
                    setIsLoading(false)
                    console.log('paymentSuccessStatus', response)
                    getUserDetails()
                    setIsPromoCodeApplied()
                    setAlert(`Payment Successful! \nThank you for your payment. Your transaction has been completed successfully. \nThis subscription will be valid for one year.`)
                })
                .catch(err => console.log(err))
        } catch (error) {
            setIsLoading(false)
            console.log("called applicant err", error);
        }
    }

    const failedPayment = (order_id, payment_id, code, description, source, step, reason) => {
        // console.log({order_id, payment_id,code,description, source, step, reason})
        try {
            let encryptedrazorpayOrderId = handleEncrypt(order_id, CryptoJSKey)
            let encryptedrazorpayPaymentId = handleEncrypt(payment_id, CryptoJSKey)
            const PurchaseDetails = {
                razorpayOrderId: encryptedrazorpayOrderId,
                razorpayPaymentId: encryptedrazorpayPaymentId,
                code: code,
                description: description,
                reason: reason,
                source: source,
                step: step
            }
            putAPICall(Url?.getOrderIdAndUpdatePaymentStatus, PurchaseDetails)
                .then((res) => {
                    console.log("payment failed status response", res);
                });
        } catch (error) {
            setIsLoading(false)
            console.log("called applicant err", error);
        }
    }

    const getUserDetails = () => {
        setIsLoading(true)
        getAPICall(Url.getuser)
            .then((res) => {
                setIsLoading(false)
                if (res?.DATA) {
                    // sessionStorage.removeItem("user");
                    setUserData(res?.DATA)
                    sessionStorage.setItem("user", JSON.stringify(res?.DATA));
                    setData(res?.DATA)
                }
                setPaymentModal(!res?.DATA?.isLicensed)
                // setPaymentModal(true)
                // window.location.reload();
            })
        setIsLoading(false)
    }

    const setIsPromoCodeApplied = () => {
        const promoUsedBody = {
            "appUser": {
                "userId": userData?.userId
            },
            "promoCode": {
                "promocodeId": promoCodeData?.promocodeId
            }
        }
        postAPICall(Url.validatePromoCode.replace('/{promocode}', ''), promoUsedBody)
            .then(res => console.log(res))
            .catch(err => console.log(err))

    }

    const applyPromoCode = () => {
        if (!!!promoCode) {
            setWarning('Promo Code is required to get discount information.')
            return
        } else if (promoCode.length < 3) {
            setWarning('Enter valid prompt code.')
            return
        }
        else if (promoCode == promoCodeData?.couponCode) {
            setWarning('Promo code discount already applied.')
            return
        }
        getAPICall(Url.validatePromoCode.replace('{promocode}', promoCode))
            .then(res => {
                // console.log('promo code......', res);
                if (/* promoCode == 'WELCOME200' */ res.SUCCESS) {
                    let taxPercent = 18
                    let price = priceDetails?.subscriptionFee
                    let discountCoupon = res.DATA?.discountAmount ?? 0 //200
                    let gstAmount = (taxPercent * price) / 100
                    setPriceDetails({
                        ...priceDetails,
                        discountCoupon,
                        payableAmount: price + gstAmount - discountCoupon,
                    })
                    setPromoCodeData(res?.DATA)
                    setAlert(`Promo code applied successfully \nyou saved ₹${discountCoupon}.`)
                } else {
                    setWarning(res?.message)
                }
            })
            .catch(err => {
                setWarning('Something went wrong please try again later.')
            })
    }

    return (
        <ComponentAndScreenConstant.CommonModal
            key={paymentModal}
            isOpen={paymentModal}
            //   onRequestClose = {()=>(false)}
            className="instituteModal"
        >
            {/* <img
                className="instituteCloseBtn"
                src={closePopup}
                onClick={() => {
                    navigate('/login')
                    sessionStorage.clear()
                }} /> */}
            <h3>Proceed with your registration</h3>
            <div className="addInstTxt">To explore jobs opportunities, please proceed with the payment.</div>
            {/* <h5>Subscription Fee: <span style={{ color: "#007749" }}>₹ {priceDetails?.subscriptionFee}</span></h5> */}
            {/* <div className="addInstTxt" style={{ color: "#000", marginTop: '12px' }}> */}
            {/* By clicking "Proceed to Payment" you will be redirected to our secure payment gateway. */}
            {/* </div> */}
            <div className="paymentDetailsDiv">
                <div className="payTxt">Payment Details</div>
                <div className="paymentDetails">
                    <div className="paymentField">
                        <span>Student Name</span> :
                        <span title={userData?.fullName}>{userData?.fullName}</span>
                    </div>
                    <div className="paymentField">
                        <span>Subscription Plan</span> :
                        <span>{"Annual"}</span>
                    </div>
                    <div className="paymentField">
                        <span>Subscription Fee</span> :
                        <span> ₹{priceDetails?.subscriptionFee}</span>
                    </div>
                    <div className="paymentField">
                        <span> {priceDetails?.gst}% GST</span> :
                        <span> ₹{priceDetails?.gstAmount}</span>
                    </div>
                    <div className="paymentField">
                        <span>Promo Code Discount</span> :
                        <span> - ₹{priceDetails?.discountCoupon}</span>
                    </div>
                    <div className="paymentField">
                        <span> Payable Amount</span> :
                        <span> ₹{priceDetails?.payableAmount}</span>
                    </div>
                </div>
            </div>
            <div className='promoCodeDiv'>
                <p>
                    {/* Enter promo code to avail discount */}
                    Enter the promo code "WELCOME200" to avail ₹200 OFF.
                </p>
                <small>
                    Expires on : {moment(userData?.createdDate).add(7, 'days').format('DD-MMM-YYYY')}
                </small>
                <div >
                    <input
                        name='promoCode'
                        placeholder='Enter promo code'
                        value={promoCode}
                        onChange={(e) => setPromoCode(e.target.value)}
                    />
                    <button onClick={applyPromoCode}>Apply</button>
                </div>
            </div>
            <div className='paymentButtonDiv'>
                <button
                    onClick={() => {
                        getOdderId()
                        // handlePayment('44566743', '62648744832', 'jay', 'jay@rnt.ai', '1234567890'/* , resolveCallback, rejectCallback */)
                    }}
                    className='paymentButton'
                    style={{ width: '200px', color: '#fff' }}>
                    Proceed to Payment
                </button>
                <button
                    onClick={() => {
                        navigate('/login')
                        sessionStorage.clear()
                    }}
                    className='cancelButton'>
                    Cancel
                </button>
            </div>
        </ComponentAndScreenConstant.CommonModal>
    )
}

export default RazorpayCheckout