import React, { useEffect, useState } from 'react'
import './HeroSection.css'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { FaStar } from "react-icons/fa6";
import 'slick-carousel/slick/slick-theme.css';
import bannerOne from '../../assets/banner1.png'
import bannerTwo from '../../assets/banner2.png'
import Employees from '../../assets/Employees.png'
import bannerThree from '../../assets/banner3.png'
import RazorpayCheckout from '../../Screens/Payment/RazorpayCheckout'
import { PiNewspaperClipping } from "react-icons/pi";
import { ComponentAndScreenConstant } from '../../constants/componentAndScreenConstant';
import { getAPICall } from '../../APIMethods/APIMethods';
import { Url } from '../../EnvoirnmentSetup/APIs';
import moment from 'moment';


const HeroSection = () => {
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    };
    let userName = JSON?.parse(sessionStorage?.getItem('userName'))
    const [isTransactionOpen, setIsTransactionOpen] = React.useState(false)
    const [userData, setUserData] = React.useState({ isLicensed: false })
    const [studentLicenseDetails, setStudentLicenseDetails] = useState([])

    const closeTransaction = () => {
        setIsTransactionOpen(false)
    }

    useEffect(() => {
        getAPICall(Url.getAllTransactionHistory)
            .then((res) => {
                setStudentLicenseDetails(res.DATA)
            })
    }, [userData])

    const handleTransactionHistory = () => {
        setIsTransactionOpen(true)
    }

    console.log(studentLicenseDetails);
    return (
        <div className='heroSectionMainDiv'>
            <div className='applicantHeroSection'>
                <div className='applicantTextDiv'>
                    <span className='helloText'>Hello, </span>
                    <h2 className='nameText' title={userName}>{userName}</h2>
                    <span className='roleText'>Student</span>
                    <div className='licenseDate'>
                        <span className='premiumText' key={userData?.isLicensed} style={{ color: userData?.isLicensed ? '#007749' : 'red' }}>
                            {userData?.isLicensed ?
                                <>
                                    Active Till : <FaStar color='orange' />
                                    {moment(studentLicenseDetails[0]?.expireDate).format('DD-MMM-YYYY')}
                                </> :
                                'Subscription not purchased'}
                        </span>
                        {/* <span >Active Till :  {studentLicenseDetails ? moment(studentLicenseDetails[0]?.expireDate).format('DD-MMM-YYYY') : ' not purchse'} </span> */}
                        {userData?.isLicensed ?
                            <button
                                className='viewTransactionBtn'
                                title='View Transaction History'
                                onClick={() => handleTransactionHistory()}>
                                <PiNewspaperClipping />
                            </button> : null}
                    </div>
                    {/* <span className='premiumText' key={userData?.isLicensed} style={{ color: userData?.isLicensed ? '#007749' : 'red' }}>
                    {userData?.isLicensed ? <><FaStar color='orange' /> Subscription Active</> : 'Subscription not purchased'} </span> */}
                </div>
                <div className='applicantEmployeeDiv'>
                    <img src={Employees} alt="" />
                </div>
            </div>
            <div className='applicantSliderHeroSection'>
                <Slider {...settings}>
                    <div className="sliderSectionOne">
                        <img src={bannerOne} alt="Banner One" />
                    </div>
                    <div className="sliderSectionOne">
                        <img src={bannerTwo} alt="Banner Two" />
                    </div>
                    <div className="sliderSectionOne">
                        <img src={bannerThree} alt="Banner Three" />
                    </div>
                </Slider>
            </div>
            <ComponentAndScreenConstant.CommonModal
                isOpen={isTransactionOpen}
                onRequestClose={closeTransaction}
            >
                <div className='transactionModal'>
                    <button onClick={closeTransaction} className='closeButton'>x</button>
                    <h1>Transaction History</h1>
                    <div className='tableDiv'>
                        <table>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Payment ID</th>
                                    <th>Amount</th>
                                    <th>Purchase Date</th>
                                    <th>Expiry Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {studentLicenseDetails?.map((item, index) => (
                                    <tr key={item?.studentLicensePaymentDto?.razorpayPaymentId}>
                                        <td>{index + 1}</td>
                                        <td>{item?.studentLicensePaymentDto?.razorpayPaymentId}</td>
                                        <td>{item?.studentLicensePaymentDto?.paymentAmount}</td>
                                        <td>{moment(item?.purchaseDate).format('DD-MMM-YYYY')}</td>
                                        <td>{moment(item?.expireDate).format('DD-MMM-YYYY')}</td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </div>
                </div>
            </ComponentAndScreenConstant.CommonModal>
            <RazorpayCheckout setData={setUserData} />
        </div>
    )
}

export default HeroSection