import React from 'react'
import './InputText.css'

const InputText = ({
labelTxt,
inputType,
inputClass,
inputValue,
onChange,
inputStyle,
inputWrapperStyle,
readOnly,
errMsg,
inputId,
placeholderTxt,
isRequired = false,
maxLength = 100
}) => {
  return (
    <div className={`${inputWrapperStyle} inputWrapper`} style={{display:"flex",flexDirection:"column"}}>
        {!!labelTxt && <label className='inputLable' htmlFor={inputId}>{labelTxt}{isRequired && <small style={{color:"#BC0000"}}>*</small>}</label>}
        <input type={inputType} id={inputId} maxLength={inputType == "number" ? 10 : maxLength} readOnly={readOnly} className={`${inputClass} inputTextClass`} placeholder={placeholderTxt} style={inputStyle} value={inputValue} onChange={onChange}/>
        {!!errMsg && <small className='errorMessageShow'>{errMsg}</small>}
    </div>
  )
}

export default InputText