import React from 'react';
import './ApplicationStatus.css'
import moment from 'moment';

const ApplicationStatusBar = props => {
  const ApplicationStatus = props.ApplicationStatus;
  // console.log({ ApplicationStatus });

  return (
    <div className='statusMainContainer'>
      <div className='statusLineContainer'>
        <div className='statusLine startAndEnd appliedStatusContainer' />
        <div className='toolTipContainer appliedStatusContainer' >
          <span className="tooltipText appliedStatusContainerToolTip">
            Applied
            <br />
            {ApplicationStatus?.intrested
              ? moment(ApplicationStatus?.intrestedDate).format('DD-MMM-YYYY')
              : <small>DD-MM-YYYY</small>}
          </span>
        </div>
      </div>
      <div className='statusLineContainer'>
        {/* {ApplicationStatus?.recruiterView && <> */}
        <div className={`statusLine ${ApplicationStatus?.recruiterView ? 'viewedStatusContainer' : 'grayContentContainer'}`} />
        <div className={`toolTipContainer ${ApplicationStatus?.recruiterView ? 'viewedStatusContainer' : 'grayContentContainer'}`}  >
          <span className={`tooltipText ${ApplicationStatus?.recruiterView ? 'viewedStatusContainerToolTip' : 'grayContentContainer'}`}>
            Viewed
            <br />
            {ApplicationStatus?.recruiterView ?
              moment(ApplicationStatus?.recruiterViewDate).format('DD-MMM-YYYY')
              : <small>DD-MM-YYYY</small>}
          </span>
        </div>
      </div>
      {/* </>} */}
      {/* {ApplicationStatus?.recruiterContacted && <> */}
      <div className='statusLineContainer'>
        <div className={`statusLine ${ApplicationStatus?.recruiterContacted ? 'contactedStatusContainer' : 'grayContentContainer'}`} />
        <div className={`toolTipContainer ${ApplicationStatus?.recruiterContacted ? 'contactedStatusContainer' : 'grayContentContainer'}`}>
          <span className={`tooltipText ${ApplicationStatus?.recruiterContacted ? 'contactedStatusContainerToolTip' : 'grayContentContainer'}`}>
            Contacted
            <br />
            {ApplicationStatus?.recruiterContacted ?
              moment(ApplicationStatus?.recruiterContactedDate).format('DD-MMM-YYYY')
              : <small>DD-MM-YYYY</small>}
          </span>
        </div>
      </div>
      {/* </>} */}
      <div className='statusLineContainer'>
        <div className={`statusLine ${ApplicationStatus?.interviewShortlisted ? 'shortlistedStatusContainer' : 'grayContentContainer'}`} />
        <div className={`toolTipContainer ${ApplicationStatus?.interviewShortlisted ? 'shortlistedStatusContainer' : 'grayContentContainer'}`}>
          <span className={`tooltipText ${ApplicationStatus?.interviewShortlisted ? 'shortlistedStatusContainerToolTip' : 'grayContentContainer'}`}>
            Shortlisted
            <br />
            {ApplicationStatus?.interviewShortlisted ?
              moment(ApplicationStatus?.interviewShortlistedDate).format('DD-MMM-YYYY')
              : <small>DD-MM-YYYY</small>}
          </span>
        </div>
      </div>
      <div className='statusLineContainer'>
        <div className={`statusLine ${ApplicationStatus?.interviewScheduled ? 'scheduledStatusContainer' : 'grayContentContainer'}`} />
        <div className={`toolTipContainer ${ApplicationStatus?.interviewScheduled ? 'scheduledStatusContainer' : 'grayContentContainer'}`}>
          <span className={`tooltipText ${ApplicationStatus?.interviewScheduled ? 'scheduledStatusContainerToolTip' : 'grayContentContainer'}`}>
            Interviewing
            <br />
            {ApplicationStatus?.interviewScheduled ?
              moment(ApplicationStatus?.interviewScheduledDate).format('DD-MMM-YYYY')
              : <small>DD-MM-YYYY</small>}
          </span>
        </div>
      </div>
      {/* {(ApplicationStatus?.closed || ApplicationStatus?.selected) && <> */}
      <div className='statusLineContainer'>
        <div className={`statusLine ${ApplicationStatus?.selected ? 'offeredStatusContainer' : ApplicationStatus?.closed ? 'closedStatusContainer' : 'grayContentContainer'}`} />
        <div className={`toolTipContainer ${ApplicationStatus?.selected ? 'offeredStatusContainer' : ApplicationStatus?.closed ? 'closedStatusContainer' : 'grayContentContainer'}`} >
          <span className={`tooltipText ${ApplicationStatus?.selected ? 'offeredStatusContainerToolTip' : ApplicationStatus?.closed ? 'closedStatusContainerToolTip' : 'grayContentContainer'}`}>
            {ApplicationStatus?.selected ? 'Offered' : ApplicationStatus?.closed ? 'Denied' : 'Offered'}
            <br />
            {ApplicationStatus?.closed
              ? moment(ApplicationStatus?.closedDate).format('DD-MMM-YYYY')
              : ApplicationStatus?.selected
                ? moment(ApplicationStatus?.selectedDate).format('DD-MMM-YYYY')
                : <small>DD-MM-YYYY</small>}
          </span>
        </div>
        <div className={`statusLine startAndEnd  ${ApplicationStatus?.selected ? 'offeredStatusContainer' : ApplicationStatus?.closed ? 'closedStatusContainer' : 'grayContentContainer'}`} />
      </div>
      {/* </>} */}
    </div>
  );
};

export default ApplicationStatusBar;
