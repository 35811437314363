import React from "react";
import "./BreadCrumb.css";
import { useNavigate } from "react-router-dom";
import Arrow from "../../assets/Breadcrumb_arrow.svg";

const Breadcrumbs = ({ currentPage, header }) => {
    let navigate = useNavigate();

    let regex = /Jobs Posted By/i;
    return (
        <nav aria-label="breadcrumb" className="breadcrumbs">
            <div className="currentTab">
                {header ?? currentPage[0]}
            </div>
            <ol className="breadcrumb">
                {currentPage.length > 1 && currentPage?.map((item, index) => (
                    <React.Fragment key={index}>
                        <li
                            aria-current="page"
                            className={index === 0 ? "breadcrumb-item active_new" : "breadcrumb-item"}
                            onClick={() => {
                                if (item.toLocaleLowerCase() === 'dashboard') {
                                    navigate('/Main/latestJob')
                                } else if (index > 0)
                                    navigate(-index)
                            }}>
                            {item.replace(regex, "")}
                        </li>
                        {index < currentPage.length - 1 && (
                            <li className="breadcrumb-divider">
                                <img src={Arrow} alt="Breadcrumb Arrow" />
                            </li>
                        )}
                    </React.Fragment>
                ))}
            </ol>
        </nav>
    );
};

export default Breadcrumbs;
