import './App.css';
import { SelectedCardProvider } from './Context/Context';
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ComponentAndScreenConstant } from './constants/componentAndScreenConstant';

function App() {
  return (
    <SelectedCardProvider>
      <HashRouter>
        <Routes>
          <Route path='/' element={<Navigate to='login' />} />
          <Route path='/login' element={<ComponentAndScreenConstant.Login />} />
          <Route path='/register' element={<ComponentAndScreenConstant.Register />} />
          <Route path='/Main/*' element={<ComponentAndScreenConstant.ApplicantDashboard />} />
          {/* <Route path='/suggestedPref' element={<ComponentAndScreenConstant.SuggestedJobsPref />} /> */}
        </Routes>
      </HashRouter>
    </SelectedCardProvider>
  );
}

export default App;
