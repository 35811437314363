import ApplicationStatusBar from "../Components/ApplicationStatus/ApplicationStatus";
import Breadcrumbs from "../Components/BreadCrumb/BreadCrumb";
import CommonModal from "../Components/CommonModal";
import DatePickerField from "../Components/DatePickerField/DatePickerField";
import Header from "../Components/Header/Header";
import HeroSection from "../Components/HeroSection/HeroSection";
import InputText from "../Components/InputText/InputText";
import LoaderModal from "../Components/LoaderModal/LoaderModal";
import SelectField from "../Components/SelectField/SelectField";
import Sidebar from "../Components/Sidebar/Sidebar";
import SuggestedJobsPref from "../Components/SuggestedJobsPref/SuggestedJobsPref";
import jobCard from "../Components/jobCard/jobCard";
import ApplicantDashboard from "../Screens/ApplicantDashboard/ApplicantDashboard";
import Dashboard from "../Screens/Dashboard/Dashboard";
import JobDetails from "../Screens/JobDetails/JobDetails";
import Login from "../Screens/Login/Login";
import MyResume from "../Screens/MyResume/MyResume";
import Notification from "../Screens/Notification/Notification";
import RecruiterInfo from "../Screens/Recruiter/RecruiterInfo";
import Register from "../Screens/Register/Register";
import Reports from "../Screens/Reports/Reports";
import SimilarJobs from "../Screens/SimilarJobs/SimilarJobs";
import UserProfile from "../Screens/profile/UserProfile";

export const ComponentAndScreenConstant = {
    // Screens
    Login,
    Register,
    ApplicantDashboard,
    Dashboard,
    SimilarJobs,
    UserProfile,
    RecruiterInfo,
    Notification,
    MyResume,
    Reports,

    // Components  
    Header,
    Sidebar,
    HeroSection,
    jobCard,
    LoaderModal,
    SuggestedJobsPref,
    InputText,
    SelectField,
    JobDetails,
    Breadcrumbs,
    ApplicationStatusBar,
    DatePickerField,
    CommonModal,
}