import './MyResume.css'
import React from 'react'
import Modal from "react-modal";
import { QRCode } from 'react-qrcode-logo';
import { FaDownload } from "react-icons/fa6";
import { RiEdit2Fill } from "react-icons/ri";
import { HiMiniSpeakerWave } from "react-icons/hi2";
import { IoLink, IoEye, IoTrash, IoQrCode } from "react-icons/io5";
import { handleCopyDetails } from '../../constants/constantFunctions';
import { ComponentAndScreenConstant } from '../../constants/componentAndScreenConstant'
import { ApiCall } from '../../APIMethods/APIMethods';
import { ResumeApiUrls, ResumeWebURL, Url } from '../../EnvoirnmentSetup/APIs';
import { useSelectedCardContext } from '../../Context/Context';
import { ModuleCards } from '../../constants/ModuleCards';
import moment from 'moment';

export default function MyResume() {

    const { selectedCard, setSelectedCard, setIsLoading, setWarning, setAlert } = useSelectedCardContext()

    const iframeRef = React.useRef(null)
    const [audioFile, setAudioFile] = React.useState(null)
    const [IsModalVisible, setIsModalVisible] = React.useState(false)
    const [generatedLink, setGeneratedLink] = React.useState(null)
    const [IsCreateResumeVisible, setIsCreateResumeVisible] = React.useState(false)
    const [Data, setData] = React.useState([
        // {
        //     id: 1,
        //     summaryAudio: '',
        //     name: 'React Developer',
        //     createdDate: '24/12/2015',
        //     viewLink: 'https://www.shareresume.co/#/Main/raphiks2509=',
        // }
    ])

    React.useEffect(() => {
        setSelectedCard(ModuleCards?.myResume)
        const user = JSON.parse(sessionStorage.getItem('user'))
        if (!!user) {
            setIsLoading(true)
            ApiCall(Url.GetResume.replace('{mobile}', user?.mobileNumber).replace('{countryCodeId}', user?.countryCode?.countryCodeId), 'GET', true)
                .then((res) => {
                    setIsLoading(false)
                    // console.log(res.DATA)
                    if (res.SUCCESS && res.DATA)
                        setData([{
                            ...res.DATA,
                            id: 1,
                            // createdDate: '',
                            name: res.DATA.userUid,
                            viewLink: `${ResumeWebURL}${res.DATA.userUid}`
                        }])
                })
        }
        if (user?.linkedinProfile?.includes('shareresume') && false) {
            let urlArray = user?.linkedinProfile.split('/')
            let userUid = urlArray[urlArray.length - 1]
            ApiCall(ResumeApiUrls.GetResumeByUid?.replace('{userUid}', userUid), 'GET', true)
                .then((res) => {
                    if (!res.DATA) return null
                    let data = Data
                    if (data.filter(d => d.name == res.DATA.userUid).length == 0)
                        data.push({
                            ...res.DATA,
                            id: Data.length + 1,
                            // createdDate: '',
                            name: res.DATA.userUid,
                            viewLink: `${ResumeWebURL}${res.DATA.userUid}`
                        })
                    setData([...data])
                })
        }
    }, [])


    return (
        <div className='mainContainer'>
            <div className='breadCrumbDiv'>
                <ComponentAndScreenConstant.Breadcrumbs currentPage={["My Resume"]} />
            </div>
            {Data.length == 0 && <button onClick={() => {
                window.open(ResumeWebURL, '_blank')
                // setIsCreateResumeVisible(true)
            }}>
                + Create Resume
            </button>}
            <div className='tableDiv'>
                {Data.length > 0 ? <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Resume Title</th>
                            <th>Create Date</th>
                            <th>Resume URL </th>
                            <th>Share</th>
                            <th>QR Code</th>
                            <th>Audio file</th>
                            <th>Download </th>
                            <th>Action </th>
                        </tr>
                    </thead>
                    <tbody>
                        {Data.map((item, index) => <>
                            <tr>
                                <td><span className='tdSpan'>{item?.id}</span></td>
                                <td><span className='tdSpan'>{item?.name}</span></td>
                                <td><span className='tdSpan'>{moment(item?.createdDate).format('DD/MM/YYYY')}</span></td>
                                <td style={{ width: '200px', wordBreak: 'break-all' }}>
                                    <a href={item?.viewLink} target='_blank'>
                                        {item?.viewLink}
                                    </a>
                                </td>
                                <td>
                                    <IoLink
                                        className='myResumeIcons'
                                        onClick={() => {
                                            if (handleCopyDetails(item?.viewLink)) {
                                                setAlert('Resume URL copied successfully')
                                            }
                                        }} />
                                </td>
                                <td>
                                    <IoQrCode
                                        className='myResumeIcons'
                                        onClick={() => {
                                            setGeneratedLink(item?.viewLink);
                                            setIsModalVisible(true)
                                            setAudioFile(null)
                                        }}
                                    />
                                </td>
                                <td>
                                    <HiMiniSpeakerWave
                                        className='myResumeIcons'
                                        onClick={() => {
                                            if (!!item?.summaryAudio) {
                                                window.open(item?.summaryAudio, '_blank');
                                            } else {
                                                setWarning('No audio file found')
                                            }
                                            // setAudioFile('')
                                            // setGeneratedLink(null);
                                            // setIsModalVisible(true)
                                        }} />
                                </td>
                                <td>
                                    <FaDownload
                                        className='myResumeIcons'
                                        onClick={() => {
                                            window.open(item?.viewLink, '_blank');
                                        }} />
                                </td>
                                <td>
                                    <div className='actionIcon'>
                                        <IoEye
                                            onClick={() => {
                                                window.open(item?.viewLink, '_blank');
                                            }} />
                                        <RiEdit2Fill
                                            onClick={() => {
                                                window.open(item?.viewLink, '_blank');
                                            }} />
                                        {/* <IoTrash color='#BC0000'
                                            onClick={() => {
                                            }} /> */}
                                    </div>
                                </td>
                            </tr>
                        </>
                        )}
                    </tbody>
                </table>
                    :
                    <>
                        <div className='notFound'>
                            <img src={require('../../assets/NotFound.png')} style={{ height: '30vh', width: '30vh', }} />
                            No resume found with existing number.
                        </div>
                    </>}
            </div>
            <ComponentAndScreenConstant.CommonModal
                isOpen={IsCreateResumeVisible}
            // onRequestClose={() => { setIsCreateResumeVisible(false) }}
            >
                <div style={{ width: '94vw', height: '92vh', borderRadius: '10px', position: 'relative' }}>
                    <button onClick={() => setIsCreateResumeVisible(false)} style={{ position: 'absolute', top: '10px', right: '10px', color: 'red', fontSize: '2rem', zIndex: 9 }}>
                        *
                    </button>
                    <iframe
                        ref={iframeRef}
                        width="100%"
                        height="100%"
                        style={{ borderRadius: '10px' }}
                        src="https://www.shareresume.co/#/"
                        frameBorder="0"
                    />
                </div>
            </ComponentAndScreenConstant.CommonModal>
            <Modal
                ariaHideApp={false}
                isOpen={IsModalVisible}
                // onRequestClose={() => {
                //     setIsModalVisible(false)
                //     setGeneratedLink(null);
                // }}
                className={'modalStyle'}
                overlayClassName={'modalOverlayStyle'}
            >
                <div className='QRModal'>
                    <button
                        onClick={() => {
                            setIsModalVisible(false)
                            setGeneratedLink(null);
                            setAudioFile(null)
                        }} >
                        X
                    </button>
                    {audioFile ? <>
                    </> : <>
                        <div>
                            <QRCode
                                value={generatedLink ?? ''}
                                size={150}
                                removeQrCodeBehindLogo={true}
                                logoPadding={5}
                                logoPaddingStyle={"circle"}
                                qrStyle={"dots"}
                                logoImage={require('../../assets/favicon.png')}
                                logoWidth={30}
                                logoHeight={30}
                                eyeRadius={[
                                    { outer: [10, 10, 0, 10], inner: [0, 10, 10, 10] }, // TL
                                    { outer: [10, 10, 10, 0], inner: [10, 0, 10, 10] }, // TR
                                    { outer: [10, 0, 10, 10], inner: [10, 10, 0, 10] }  // BL
                                ]}
                                eyeColor="#01774a" // Change eye color if needed
                            />
                        </div>
                        <span>Scan QR code to view the resume</span>
                    </>}
                </div>
            </Modal>
        </div>
    )
}
